import { catchError } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { NotificationService } from '../../notification';
import { Observable, throwError } from 'rxjs';
import { ApiErrorStatus } from '../enums';

export class InternalServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === ApiErrorStatus.INTERNAL) {
            this.notificationService.error('Failed to perform action: Internal Server error occurred.');
          }

          return throwError(response);
        }),
      );
  }
}
