import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { configuration } from '@configurations';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-root',
  templateUrl: 'app.html',
  styleUrls: ['app.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {
    this.angulartics2GoogleTagManager.startTracking();
  }

  public ngOnInit(): void {
    this.translateService.addLangs(configuration.language.available);
    this.translateService.setDefaultLang(configuration.language.default);

    this.setLanguage(this.translateService.getBrowserLang());
  }

  private setLanguage(language: string): void {
    const isLanguageAvailable = configuration.language.available.includes(language);
    if (isLanguageAvailable) {
      this.translateService.use(language);
    } else {
      this.translateService.use(configuration.language.default);
    }
  }
}
