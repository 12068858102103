import { ApiService } from './api.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ContentTypeInterceptor, InternalServerErrorInterceptor, ForbiddenErrorInterceptor, ValidationErrorInterceptor } from './interceptors';
import { NotificationService, NotificationModule } from '../notification';

@NgModule({
  imports: [
    HttpClientModule,
    NotificationModule
  ],
  providers: [
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternalServerErrorInterceptor,
      deps: [NotificationService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForbiddenErrorInterceptor,
      deps: [NotificationService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ValidationErrorInterceptor,
      deps: [NotificationService],
      multi: true
    }
  ]
})
export class ApiModule { }
