import { catchError } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { NotificationService } from '../../notification';
import { Observable, throwError } from 'rxjs';
import { ApiErrorStatus } from '../enums';
import { keys, values, castArray } from 'lodash';

export class ValidationErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === ApiErrorStatus.VALIDATION) {
            const [error] = values(response.error.errors);
            const message = (error) ? castArray(error)[0] : 'Please check the input you entered.';
            this.notificationService.error('Invalid data provided: ' + message);
          }

          return throwError(response);
        }),
      );
  }
}
