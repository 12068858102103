const apiDomain ='staging-api.logobly.com'
const apiURL = 'https://' + apiDomain;


//const apiURL = 'http://127.0.0.1:8000';
export const configuration = {
  production: false,
  api: {
    url: apiURL,
    mediaUrl: apiURL + '/media/',
    whitelisted_domains: [
      apiDomain
    ],
    unauthorized_routes: [
      apiURL + '/login'
    ]
  },
  url: 'http://localhost:4200',
  language: {
    available: ['en'],
    default: 'en'
  },
  notifications: {
    defaultConfig: {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: 'notification'
    }
  },
  sentry: {
    dsn: 'http://test.com/sentry-dsn'
  },
  colors: [
    '#4E81EE',
    '#24BE5A',
    '#F7C328',
    '#FF5858',
    '#FF9A00',
    '#B206B0',
    '#FF62A5'
  ],
  stripe: {
    defaultCountry: 'US',
    apiPublishableKey: 'pk_test_enL74ZElPZPgGMB4KmJ1G7Xq'
  },
  suggestCategoryLnk: 'https://logobly.com/suggest'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
